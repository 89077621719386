import { useState } from "react";
import Input from "../common/input/Input";
import SelectDropdown from "../common/select-dropdown/SelectDropdown";
import axios from "axios";
import { useAuthContext } from "../../context/AuthContextProvider";

type AvailabilityInquiryOptionsAPI = {
	start_date: string; // Unix with milliseconds
	end_date: string; // Unix with milliseconds
	listing_id: string;
	num_adults: string;
	num_children: string;
	num_infants: string;
	travel_reason?: string;
	special_request?: string;
};

type Props = {
	isOpen: boolean;
	onClickAway?: () => void;
	listingId: string;
};

export default function CheckAvailabilityModal({ isOpen, onClickAway, listingId }: Props) {
	const [startDate, setStartDate] = useState<string>();
	const [endDate, setEndDate] = useState<string>();
	const [numberAdults, setNumberAdults] = useState<number>();
	const [numberChildren, setNumberChildren] = useState<number>();
	const [numberInfants, setNumberInfants] = useState<number>();
	const [travelReason, setTravelReason] = useState<string>();
	const [specialRequest, setSpecialRequest] = useState("");

	const [isLoading, setIsLoading] = useState(false);

	const { user } = useAuthContext();
	const isLoggedIn = !!user;

	const peopleOptions = Array(6)
		.fill(null)
		.map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

	const travelOptions = [
		{ value: "business", label: "Business" },
		{ value: "pleasure", label: "Pleasure" }
	];

	async function onClickSubmit() {
		if (!isLoggedIn) {
			return;
		}

		if (!startDate || !endDate) {
			return alert("Please fill out all required fields.");
		}

		try {
			setIsLoading(true);

			const options: AvailabilityInquiryOptionsAPI = {
				start_date: `${new Date(startDate).valueOf()}`,
				end_date: `${new Date(endDate).valueOf()}`,
				listing_id: listingId,
				num_adults: `${numberAdults}`,
				num_children: `${numberChildren}`,
				num_infants: `${numberInfants}`,
				travel_reason: travelReason,
				special_request: specialRequest
			};

			await axios.post(
				"/listings/inquire-availability-email?" + new URLSearchParams(options).toString()
			);

			if (onClickAway) {
				onClickAway();
			}

			alert("Email sent!");
		} catch (err) {
			alert("Oops! Something went wrong.");
		} finally {
			setIsLoading(false);
		}
	}

	console.log({ startDate, endDate });

	return (
		<div
			className={`availability-modal p-4  d-flex flex-column gap-08  ${
				isOpen && "availability-modal-open"
			}`}>
			<div className="d-flex justify-content-between text-color-black fw-700 text-big lh-120 ">
				Choose dates <img onClick={onClickAway} src="./images/icons/x.svg" alt="X" />
			</div>
			<div className="d-flex gap-08 mt-1">
				<Input
					type="date"
					className="flex-fill"
					label="Check-in-date"
					placeholder="Mon 14 Sept 2023"
					value={startDate}
					onChange={e => setStartDate(e.target.value)}
				/>
				<Input
					type="date"
					className="flex-fill"
					label="Check-out-date"
					placeholder="Thur 17 Sept 2023"
					value={endDate}
					onChange={e => setEndDate(e.target.value)}
				/>
			</div>
			<div className="d-flex w-100 gap-08 mt-1">
				<div className="d-flex flex-column flex-grow-1">
					<label>Adults</label>
					<SelectDropdown
						options={peopleOptions}
						placeholder="0"
						value={numberAdults}
						onChange={e => setNumberAdults(e as number)}
					/>
				</div>
				<div className="d-flex flex-column flex-grow-1">
					<label>Children</label>
					<SelectDropdown
						options={peopleOptions}
						placeholder="0"
						value={numberChildren}
						onChange={e => setNumberChildren(e as number)}
					/>
				</div>
				<div className="d-flex flex-column flex-grow-1">
					<label>Infants</label>
					<SelectDropdown
						options={peopleOptions}
						placeholder="0"
						value={numberInfants}
						onChange={e => setNumberInfants(e as number)}
					/>
				</div>
			</div>
			<div>
				<label>Traveling for</label>
				<SelectDropdown
					options={travelOptions}
					placeholder="Business"
					value={travelReason}
					onChange={e => setTravelReason(e as string)}
				/>
			</div>
			<div>
				<label>Special request</label>
				<textarea
					placeholder="Message here..."
					className="form-control input rounded-3  h-100"
					rows={4}
					value={specialRequest}
					onChange={e => setSpecialRequest(e.target.value)}
				/>
			</div>
			<button
				disabled={!isLoggedIn || isLoading}
				className="btn btn-primary btn-disabled-gray w-100 mt-3"
				onClick={onClickSubmit}>
				{isLoggedIn
					? isLoading
						? "Loading..."
						: "Send Inquiry"
					: "Please log in to send an inquiry"}
			</button>
		</div>
	);
}
